import { Box, Stack, Tab, Tabs } from "@mui/material";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import globalColors from "../../globalColors";
import Badge, { BadgeProps } from '@mui/material/Badge';
import { BadgeCounts } from "../../store/slices/exams";
import { structureMockProps } from "../../store/slices/constants/staticContents";

const StyledTabs = styled(Tabs)`
  .MuiButtonBase-root {
    font-size: 1rem;
    min-height: 0;
    padding: 10px 25px 10px 10px;
    min-width: 99px;
    font-weight: 500;
    text-transform:capitalize;
    color: ${globalColors.primary.black};
  }
  .MuiTabs-flexContainer {
    gap : 25px;
  }
  .Mui-selected {
    color: ${globalColors.primary.black};
  }
  .MuiTabScrollButton-root {
    min-width:fit-content !important;

  }

  .MuiTab-textColorInherit {
    color: #000; // Text color for unselected tabs
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ flex: 8 ,overflow:"auto"}}
    >
      {value === index && children}
    </div>
  );
}

export interface TabProp {
  id: number;
  tab: string;
  content: React.ReactNode;
}

interface CustomTabProps {
  tabs: TabProp[];
  selected?: TabProp;
  onChange(data: number): void;
  badgeCount: BadgeCounts
  dockedCardTitle?: string;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -12,
    top: 9,
  },
}));

function CustomTab({ badgeCount, tabs, selected, onChange,dockedCardTitle }: CustomTabProps) {
  const [value, setValue] = React.useState(selected ? selected.id : 10);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (value !== (selected ? selected.id : 10)) {
      setValue(selected ? selected.id : 10);
    }
  }, [selected]);
  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);
  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", m: "5px 3px" }}>
        <StyledTabs
          sx={{ minHeight: "0" }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              style ={{borderRight: dockedCardTitle == "questions" && tab.tab == "ready" ? "1px solid #000000" : "0px"}}
              key={tab.id}
              label={
                 // Only show badge if count is greater than zero
                 badgeCount[tab.tab] > 0 ? (
                  <StyledBadge badgeContent={badgeCount[tab.tab]} color="primary">
                    {tab.tab}
                  </StyledBadge>
                ) : (
                  tab.tab
                )
              }
              //label ={tab.tab}
              value={tab.id}
              {...a11yProps(tab.id)}
            />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map((tab) => (
        <CustomTabPanel key={tab.id} value={value} index={tab.id}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Stack>
  );
}

export default CustomTab;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
