import CustomCard from "../CustomCard";
import { MenuItem, Stack } from "@mui/material";
import { headerContent } from "../CustomTexts";
import globalColors from "../../globalColors";
import { ArrowRight, expand } from "../../assets/svgs";
import CustemListItem from "../CustemListItem";
import CustomIconButton from "../CustomIconButton";
import { store } from "../../store/store";
import {
  ModuleDetailsInMockTest,
  allModulesInMockTest,
  allStatusInMock,
  allStatusInMockTestModule,
  allStatusInMockTestModule2,
  checkPermisions,
  getNextModuleInMockTest,
  structureProps,
} from "../../store/slices/constants/staticContents";
import { setExamStructure, setExpand } from "../../store/slices/exams";
import { formatStructureData } from "../../pages/mockTest";
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { ObjectValues } from "../../pages/login";
import CustomTextField from "../CustomTextfield";
import { useEffect, useState } from "react";
import { fetchListData, fetchMockListData } from "../../store/slices/exams/thunks/fetchListData";
import { allApis } from "../../request/config";
const expandButtonCss = {
  background: globalColors.primary.pureWhite,
  position: "absolute",
  top: "40px",
  borderTop: "1px solid #C8C8C8",
  borderBottom: "1px solid #C8C8C8",
  borderRadius: "0 15px 15px 0",
  borderRight: "1px solid #C8C8C8",
  writingMode: "vertical-rl",
  boxShadow: "4px 0px 6px 0px #00000040",
  padding: "10px 3px 10px",
};
export const ExpandButton = () => {
  return (
    <CustomIconButton
      onClick={() => {
        store.dispatch(setExpand());
      }}
      sx={{
        ...expandButtonCss,
        left: "-8px",
      }}
      icon="Expand"
      content={expand}
    // {
    //   <Stack sx={{ ...rowVerticalCenter }} spacing={"4px"}>
    //     <Typography
    //       fontStyle={"normal"}
    //       fontWeight={400}
    //       fontSize={"0.7rem"}
    //       lineHeight={"1rem"}
    //       color={"currentColor"}
    //       textTransform={"capitalize"}
    //     >
    //       {}
    //     </Typography>
    //     {expand}
    //   </Stack>
    // }
    />
  );
};
export const CollapseButton = () => {
  return (
    <CustomIconButton
      onClick={() => {
        store.dispatch(setExpand());
      }}
      sx={{
        ...expandButtonCss,
        right: "-8px",
        transform: "rotate(-180deg)",
      }}
      icon={"Collapse"}
      content={expand}
    />
  );
};
function ExpandViewCardMock({
  item,
  singleCard,
}: {
  item: structureProps;
  singleCard?: true;
}) {
  const exams_structure: structureProps = useAppSelecter(
    (state: ObjectValues) => state.exams.exams_structure
  );
  const is_expanded: boolean = useAppSelecter(
    (state: ObjectValues) => state.exams.is_expanded
  );
  const userSlice: ObjectValues = useAppSelecter(
    (state: ObjectValues) => state.userSlice.loggedUser
  );
  const formatted_exams_structure: structureProps[] = useAppSelecter(
    (state: ObjectValues) => state.exams.formatted_exams_structure
  );


  const dispatch = useAppDispatch();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  const getGoalId = (title: string) => {
    return formatted_exams_structure.find((item) => item.title === title)?.selected_row?.id

  };

  const getParentTitle = (title: string) => {
    switch (title) {
      case "goals":
        return "exams";
      case "mock Test Series":
        return "goals";
      case "mock Tests":
        return "mock Test Series";
      case "section":
        return "mock Tests";
      case "question":
        return "section";
      default:
        return "exams"
    }
  };

  // const statusArray = item.title === 'exams' || item.title === 'goals' ? allStatusInMockTestModule :
  // item.title === 'mock Test Series' || item.title === 'mock Tests' ? allStatusInMockTestModule2 : allStatusInMockTestModule;

  useEffect(() => {
    if (item.fetchAgain === true) {
      dispatch(
        fetchMockListData({
          api: `${allApis[item.title]?.list(item.title === allModulesInMockTest[5] ?
            item.selectedTabInMockTest3?.label.toLowerCase()  :
            item.selectedTabInMockTest?.label.toLowerCase(),
            getGoalId(getParentTitle(item.title)),
            item.title === allModulesInMockTest[1] ||
              item.title === allModulesInMockTest[2] ||
              item.title === allModulesInMockTest[3] ||
              item.title === allModulesInMockTest[4] ||
              item.title === allModulesInMockTest[5]
              ? getGoalId(item.title)
              : ""
          )}`,
          method: "GET",
          payload: {},
          token: `mock${item.title}`,
          extra: item,
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.fetchAgain]);
  const [tableData, setTableData] = useState<ObjectValues>({
    actual: [],
    copy: [],
    all_status: [],
  });
  useEffect(() => {
    const moduleTitle = item?.title;
    let filterLabel: string = "all";
    if (moduleTitle == "goals" || moduleTitle == "exams") {
      filterLabel = item?.selectedTabInMockTest?.label
    } else if (moduleTitle == "mock Test Series" || moduleTitle == "mock Tests") {
      filterLabel = item?.selectedTabInMockTest2?.label
    } else if (moduleTitle == "question") {
      filterLabel = item?.selectedTabInMockTest3?.label
    }
    const filteredData = ModuleDetailsInMockTest[item.title]?.formatList(item.list)?.filter((it) => it.status === filterLabel)
      .filter((it) => it.is_active)
    const sectionsData = ModuleDetailsInMockTest[item.title]?.formatList(item.list)?.filter((it) => it?.section?.is_active || it?.is_active)
    ?.map((it: any) => {
      if(it.section) {
        return {...it?.section, id: it?.id}
      } else {
        return {...it}
      }
    })
 
      setTableData(
        ModuleDetailsInMockTest[item.title]?.arrange_path
            ? {
                all_status: item.title == 'section' ? sectionsData : filteredData,
                actual: item.title == 'section' ? sectionsData : filteredData,
                copy: item.title == 'section' ? sectionsData : filteredData,
            }
            : {
                all_status: item.title == 'section' ? sectionsData : filteredData,
                actual: item.title == 'section' ? sectionsData : filteredData,
                copy: item.title == 'section' ? sectionsData : filteredData,
            }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return checkPermisions(
    userSlice?.user_permissions ?? [],
    "view",
    "exam",
    "syllabus"
  ) ? (
    <CustomCard
      sx={{
        maxWidth: "250px",
        minWidth: singleCard ? "150px" : "",
        p: "10px 0",
        boxShadow: "none",
        position: "relative",
        flex: "8",
      }}
      content={
        <Stack spacing={"15px"}>
          {headerContent({
            size: "0.8rem",
            text: item.title,
            sx: {
              p: "0 10px 10px",
              borderBottom: `1px solid ${globalColors.primary.borderColor}`,
              color: globalColors.primary.normalText,
              textTransform: "capitalize",
            },
            icons: [
              //   {
              //     content: iconText({ icon: Addicon }),
              //     variant: "outlined",
              //     onClick: () => {
              //       store.dispatch(
              //         setExamStructure(
              //           formatStructureData(
              //             exams_structure,
              //             { create: true },
              //             item.title
              //           )
              //         )
              //       );
              //     },
              //     sx: { p: "5px" },
              //   },
            ],
          })}
          <Stack spacing={"5px"} minWidth={"125px"} p="0 5px">
            {/* {item?.title == "mock Test Series" ? console.log(item, item.selectedTabInMockTest2?.label, allStatusInMockTestModule2[0]) : ""} */}
            {ModuleDetailsInMockTest[item.title]?.isTab && (
              <CustomTextField
                required={true}
                autoComplete="off"
                // value={item.selectedTab?.label}
                // value={(item?.title == "mock Tests" || item.title === "mock Test Series") ?
                //   item.selectedTabInMockTest2?.label : (item.title === "question") ?
                //     item.selectedTabInMockTest3?.label : item.selectedTabInMockTest?.label || item.selectedTab?.label}
                value={(item?.title == "goals" || item?.title == "exams") ? item.selectedTabInMockTest?.label 
                   : (item?.title == "mock Test Series" || item?.title == "mock Tests") ? item?.selectedTabInMockTest2?.label 
                   :(item?.title == "question"  && !is_expanded) ? item?.selectedTabInMockTest3?.label :
                   item?.selectedTabInMockTest3?.label }
                sx={{
                  width: "100%",
                  marginBottom: "15px !important",
                  textTransform: "capitalize",
                  "& .MuiInputBase-root": { padding: "5px 7px !important" },
                  "& .MuiInputBase-input": { padding: "0 !important" },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        margin: "1px 0 0 2px",
                        borderRadius: "10px",
                      },
                    },
                  },
                }}
                id="outlined-select-currency-native"
                select
                // disabled={true}
                variant="outlined"
                onChange={(e) => {
                  store.dispatch(
                    setExamStructure(
                      formatStructureData(
                        exams_structure,
                        {
                          selectedTab: allStatusInMock.find(
                            (it) => it.label === e.target.value
                          ),
                          fetchAgain: true,
                          loading: true,
                          selectedTabInMockTest: allStatusInMockTestModule.find(
                            (it) => it.label === e.target.value
                          ),
                          selectedTabInMockTest2: allStatusInMockTestModule2.find(
                            (it) => it.label === e.target.value
                          ),
                          selectedTabInMockTest3: allStatusInMock.find(
                            (it) => it.label === e.target.value
                          ),
                        },
                        item.title
                      )
                    )
                  );
                }}
              >
                {item.title == "exams" || item.title == "goals" ? allStatusInMockTestModule?.map(({ label }, i) => (
                  <MenuItem
                    key={`${label}-${i}`}
                    value={label}
                    id={label}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {label}
                  </MenuItem>
                )) : item.title == "mock Test Series" || item.title == "mock Tests" ? allStatusInMockTestModule2?.map(({ label }, i) => (
                  <MenuItem
                    key={`${label}-${i}`}
                    value={label}
                    id={label}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {label}
                  </MenuItem>
                )) : allStatusInMock?.map(({ label }, i) => (
                  <MenuItem
                    key={`${label}-${i}`}
                    value={label}
                    id={label}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
            {item.loading
              ? ""
              : tableData.actual &&
              tableData.actual.length > 0 &&
              tableData.actual.map((li: any, i: number) => {
                return (
                  <CustemListItem
                    key={`${li.name}-${i}`}
                    ListItemButtonsx={{ maxHeight: "fit-content" }}
                    keyId={`${li.name}`}
                    isSelected={li.id === item?.selected_row?.id}
                    selectedColor={globalColors.primary.lightGrey}
                    onClick={() => {
                      store.dispatch(
                        setExamStructure(
                          formatStructureData(
                            exams_structure,
                            {
                              selected_row: li,
                              dockedTable: false,
                              dockedList: is_expanded ? item.dockedList : true,

                              child: item.child
                                ? {
                                  ...item.child,
                                  fetchAgain: true,
                                  loading: true,
                                  parentDetails: li,
                                  selectedTabInMockTest: allStatusInMockTestModule[0],
                                  selectedTabInMockTest2: allStatusInMockTestModule2[0],
                                  selectedTabInMockTest3: allStatusInMock[0]
                                }
                                : 
                                {
                                  fetchAgain: true,
                                  title: getNextModuleInMockTest(item.title),
                                  parentDetails: li,
                                  list: [],
                                  child: null,
                                  loading: true,
                                  create: false,
                                  edit: false,
                                  selected_row: null,
                                  dockedTable: true,
                                  dockedList: false,
                                  selectedTab: allStatusInMock[0],
                                  selectedTabInMockTest: allStatusInMockTestModule[0],
                                  selectedTabInMockTest2: allStatusInMockTestModule2[0],
                                  selectedTabInMockTest3: allStatusInMock[0]
                                },
                            },
                            item.title
                          )
                        )
                      );
                    }}
                    direction="reverse"
                    primary={li.cluster?.name ? li.cluster?.name :
                      li.translation && li.translation[0]?.text ? <div
                        dangerouslySetInnerHTML={{
                          __html: li.translation[0]?.text ?? "",
                        }}
                      /> : li.name}
                    icon={ArrowRight}
                  />
                );
              })}
          </Stack>
        </Stack>
      }
    />
  ) : (
    <></>
  );
}

export default ExpandViewCardMock;
